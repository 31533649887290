jQuery(document).ready(function () {

	var langArray = {
		fi: {
			name: 'Suomeksi',
			title: 'Vanhat painetut kartat',
			products: 'Valitse tuote',
			search: 'Haku',
			selected_products: 'Valitut tuotteet'
		},
		sv: {
			name: 'På svenska',
			title: 'Gamla tryckta kartor',
			products: 'Välj produkt',
			search: 'Sök',
			selected_products: 'Valda produkter'
		},
		en: {
			name: 'In English',
			title: 'Old printed maps',
			products: 'Select product',
			search: 'Search',
			selected_products: 'Selected products'
		}
	};

	function getURLParameter(name) {
		var value = (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, null])[1];
		if (value) {
			return decodeURI(value);
		}
	}

	function isSupportedLocale(lang) {
		for (l in langArray) {
			if (langArray.hasOwnProperty(l)) {
				if (l === lang) {
			  		return true;
				}
		  	}
		}
		return false;
	}

	function translateUI(lang) {
		var lang_links = '';
		for (var i in langArray) {
			var cl = (i == lang) ? 'current_lang' : 'option';
			lang_links += '<li><a class="' + cl + '" href="?lang=' + i + '">' + langArray[i].name + '</a></li>';
		}
		jQuery('header ul.language-choices').html(lang_links);
		jQuery('header img').prop('src', '/Oskari/applications/vapaka/images/mml-logo_' + lang + '.svg');
		jQuery('header h1').html(langArray[lang].title);
		jQuery(document).attr('title', langArray[lang].title);
		jQuery('#panel-left h3').html(langArray[lang].products);
		jQuery('#panel-right h3:first').html(langArray[lang].search);
		jQuery('#panel-right h3:not(:first)').html(langArray[lang].selected_products);
	}

	var language = getURLParameter('lang');
	if (!language || !isSupportedLocale(language)) {
		// default to finnish
		language = 'fi';
	}

	Oskari.setLang(language);

	// create general ui stuff
	translateUI(language);

	jQuery('.accordion').accordion({
		heightStyle: "fill",
		collapsible: false
	});

	function onError(foo) {
		jQuery('#mapdiv').append('Unable to start');
	}

	function onSuccess() {
		//noop
	}

	Oskari.app.loadAppSetup('/appsetup.json', {}, onError, onSuccess);

});
