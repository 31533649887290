/**
 * @class Oskari.mapframework.bundle.search.SearchBundleInstance
 *
 * Main component and starting point for the "search" functionality.
 * Provides search functionality for the map.
 *
 * See Oskari.mapframework.bundle.search.SearchBundle for bundle definition.
 *
 */
Oskari.clazz.define("Oskari.mapframework.bundle.search.SearchBundleInstance",

/**
 * @method create called automatically on construction
 * @static
 */
function() {
	this.sandbox = null;
	this.started = false;
	this.plugins = {};
	this.service = null;
}, {
	/**
	 * @static
	 * @property __name
	 */
	__name : 'Search',
	/**
	 * @method getName
	 * @return {String} the name for the component
	 */
	"getName" : function() {
		return this.__name;
	},
	/**
	 * @method setSandbox
	 * @param {Oskari.mapframework.sandbox.Sandbox} sandbox
	 * Sets the sandbox reference to this component
	 */
	setSandbox : function(sandbox) {
		this.sandbox = sandbox;
	},
	/**
	 * @method getSandbox
	 * @return {Oskari.mapframework.sandbox.Sandbox}
	 */
	getSandbox : function() {
		return this.sandbox;
	},
	/**
	 * @method start
	 * implements BundleInstance protocol start methdod
	 */
	start : function() {
		var me = this;

		if (me.started) {
			return;
		}

		me.started = true;

		var sandbox = Oskari.getSandbox();
		me.sandbox = sandbox;
		
		var ajaxUrl = Oskari.urls.getRoute('GetSearchResult');

		var servName = 'Oskari.service.search.SearchService';
		this.service = Oskari.clazz.create(servName, sandbox, ajaxUrl);

		sandbox.register(me);
		for (p in me.eventHandlers) {
			sandbox.registerForEventByName(me, p);
		}

		//Let's extend UI
		var reqName = 'userinterface.AddExtensionRequest';
		var reqBuilder = Oskari.requestBuilder(reqName);
		var request = reqBuilder(this);
		sandbox.request(this, request);

		sandbox.registerAsStateful(this.mediator.bundleId, this);

		// draw ui
		me.createUi();
	},
	/**
	 * @method init
	 * implements Module protocol init method - does nothing atm
	 */
	"init" : function() {
		return null;
	},
	/**
	 * @method update
	 * implements BundleInstance protocol update method - does
	 * nothing atm
	 */
	"update" : function() {

	},
	/**
	 * @method onEvent
	 * @param {Oskari.mapframework.event.Event} event a Oskari event
	 * object
	 * Event is handled forwarded to correct #eventHandlers if found
	 * or discarded if not.
	 */
	onEvent : function(event) {

		var handler = this.eventHandlers[event.getName()];
		if (!handler)
			return;

		return handler.apply(this, [event]);

	},
	/**
	 * @property {Object} eventHandlers
	 * @static
	 */
	eventHandlers : {
	},

	/**
	 * @method stop
	 * implements BundleInstance protocol stop method
	 */
	"stop" : function() {
		var sandbox = this.sandbox();
		for (p in this.eventHandlers) {
			sandbox.unregisterFromEventByName(this, p);
		}

		var reqName = 'userinterface.RemoveExtensionRequest';
		var reqBuilder = Oskari.requestBuilder(reqName);
		var request = reqBuilder(this);

		sandbox.request(this, request);

		this.sandbox.unregisterStateful(this.mediator.bundleId);
		this.sandbox.unregister(this);
		this.started = false;
	},
	/**
	 * @method startExtension
	 * implements Oskari.userinterface.Extension protocol
	 * startExtension method
	 * Creates a flyout and a tile:
	 * Oskari.mapframework.bundle.publisher.Flyout
	 * Oskari.mapframework.bundle.publisher.Tile
	 */
	startExtension : function() {
		this.plugins['Oskari.userinterface.Flyout'] = Oskari.clazz.create('Oskari.mapframework.bundle.search.Flyout', this);
		this.plugins['Oskari.userinterface.Tile'] = Oskari.clazz.create('Oskari.mapframework.bundle.search.Tile', this);
	},
	/**
	 * @method stopExtension
	 * implements Oskari.userinterface.Extension protocol
	 * stopExtension method
	 * Clears references to flyout and tile
	 */
	stopExtension : function() {
		this.plugins['Oskari.userinterface.Flyout'] = null;
		this.plugins['Oskari.userinterface.Tile'] = null;
	},
	/**
	 * @method getPlugins
	 * implements Oskari.userinterface.Extension protocol getPlugins
	 * method
	 * @return {Object} references to flyout and tile
	 */
	getPlugins : function() {
		return this.plugins;
	},
	/**
	 * @method getTitle
	 * @return {String} localized text for the title of the component
	 */
	getTitle : function() {
		return Oskari.getMsg('Search', 'title');
	},
	/**
	 * @method getDescription
	 * @return {String} localized text for the description of the
	 * component
	 */
	getDescription : function() {
		return Oskari.getMsg('Search', 'desc');
	},
	/**
	 * @method createUi
	 * (re)creates the UI for "selected layers" functionality
	 */
	createUi : function() {
		var me = this;
		this.plugins['Oskari.userinterface.Flyout'].createUi();
		this.plugins['Oskari.userinterface.Tile'].refresh();
	},
	/**
	 * @method setState
	 * @param {Object} state bundle state as JSON
	 */
	setState : function(state) {
		this.plugins['Oskari.userinterface.Flyout'].setState(state);
	},
	/**
	 * @method getState
	 * @return {Object} bundle state as JSON
	 */
	getState : function() {
		return this.plugins['Oskari.userinterface.Flyout'].getState();
		/*
		 var state = {

		 };

		 return state;
		 */
	}
}, {
	/**
	 * @property {String[]} protocol
	 * @static
	 */
	"protocol" : ['Oskari.bundle.BundleInstance', 'Oskari.mapframework.module.Module', 'Oskari.userinterface.Extension']
});
