/**
 * @class Oskari.sample.bundle.myfirstbundle.SimpleHelloWorldBundle
 *
 * Definition for bundle. See source for details.
 */
Oskari.clazz.define("Oskari.vapaka.bundle.products.ProductsBundle",
/**
 * @method create called automatically on construction
 * @static
 */
function () {}, {
  "create": function create() {
    return Oskari.clazz.create("Oskari.vapaka.bundle.products.ProductsBundleInstance");
  },
  "update": function update(manager, bundle, bi, info) {}
}, {
  "protocol": ["Oskari.bundle.Bundle"],
  "source": {
    "scripts": [{
      "type": "text/css",
      "src": "../../../../resources/vapaka/bundle/products/css/style.css"
    }, {
      "type": "text/javascript",
      "src": "../../../../bundles/vapaka/bundle/products/instance.js"
    }]
  },
  "bundle": {
    "manifest": {
      "Bundle-Identifier": "products",
      "Bundle-Name": "products",
      "Bundle-Author": [{
        "Name": "ev",
        "Organisation": "nls.fi",
        "Temporal": {
          "Start": "2009",
          "End": "2011"
        },
        "Copyleft": {
          "License": {
            "License-Name": "EUPL",
            "License-Online-Resource": "http://www.paikkatietoikkuna.fi/license"
          }
        }
      }],
      "Bundle-Name-Locale": {
        "fi": {
          "Name": "vapaka-products",
          "Title": "vapaka-products	"
        },
        "en": {}
      },
      "Bundle-Version": "1.0.0",
      "Import-Namespace": ["Oskari"],
      "Import-Bundle": {}
    }
  },
  /**
   * @static
   * @property dependencies
   */
  "dependencies": []
});
Oskari.bundle_manager.installBundleClass("products", "Oskari.vapaka.bundle.products.ProductsBundle");
import '../../../../resources/vapaka/bundle/products/css/style.css'
import '../../../../bundles/vapaka/bundle/products/instance.js'